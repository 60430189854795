import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import {
  checkIfUserNameExist,
  mobileVerification,
  registerUser,
  sendOtpToMobile,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { APP_CONST } from "../../../config/const";
import { Toaster } from "../../../utils/toaster";
import { parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import Whatsapp from "../../../assets/images/Whatsapp.svg";
// import LoginLeftPart from "../../../assets/images/modal.gif";

const RegisterModal = ({
  show,
  handleClose,
  handleModalShow,
  agentCode: agentCodeProp,
}) => {
  const { appDetails } = useSelector((state) => state.app);
  const validationMessages = [
    { text: "at least 8 symbols", key: 1 },
    { text: "at least 1 digit (0-9)", key: 2 },
    { text: "at least 1 lowercase characters (a-z)", key: 3 },
    { text: "at least 1 uppercase characters (A-Z)", key: 4 },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPasswordValidtions, setShowPasswordValidations] = useState(false);
  const [validationIndex, setValidationIndex] = useState([]);
  const OTP = useSelector((state) => state?.app?.appDetails?.OTP);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [agentCode, setAgentCode] = useState(agentCodeProp);

  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtpValue] = useState("");
  const [userNameConfig, setUserNameConfig] = useState({
    isUserNameExist: false,
    message: "User Name is available",
  });

  const checkForPasswordValidations = (e) => {
    const password = e.target.value;
    let fulfilledindex = [];
    if (password.length >= 8) {
      fulfilledindex.push(1);
    }
    if (/\d/.test(password)) {
      fulfilledindex.push(2);
    }
    const smallAlp = new RegExp("(?=.*[a-z])");
    if (smallAlp.test(password)) {
      fulfilledindex.push(3);
    }
    const capAlp = new RegExp("(?=.*[A-Z])");
    if (capAlp.test(password)) {
      fulfilledindex.push(4);
    }
    setValidationIndex(fulfilledindex);
  };

  const validateData = (data = null) => {
    const dataToValidate = data ? data : formData;
    const { name, username, password, confirmPassword } = dataToValidate;
    const newErrors = {};
    if (!name || name === "") {
      newErrors.name = "Name cannot be empty";
    }
    if (!username || username === "") {
      newErrors.userName = "User name cannot be empty";
    }
    if (username && (username.length < 4 || username.length > 15)) {
      newErrors.userName = "Username must be 4 to 15 characters.";
    }
    if (!/^[A-Za-z0-9 ]+$/.test(username)) {
      newErrors.userName = "Special characters are not allowed in username.";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    if (password && (password.length < 8 || password.length > 20)) {
      newErrors.password = "Password must be 8 to 20 characters.";
    }
    if (!confirmPassword || confirmPassword === "") {
      newErrors.confirmPassword = "Confirm password cannot be empty";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Password and Confirm password must be same";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorsData = validateData();

    if (Object.keys(errorsData).length > 0) {
      //setErrors(errorsData);
    } else {
      let dataToSend = { ...formData };
      delete dataToSend.confirmPassword;
      if (agentCode) {
        dataToSend.agent_code = agentCode;
      }
      dispatch(
        registerUser(
          {
            ...dataToSend,
            typeId: 3,
            parentId: 81026,
            mobile: registerConfig.mobileDetails?.mobile,
            country_code: registerConfig.mobileDetails.country_code,
          },
          navigate
        )
      );
      // checkIfExistingUserDetails(formData);
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
    validateData(tempFormData);
  };

  const [registerConfig, setRegisterConfig] = useState({
    isOtpEnabled: useSelector((state) => state?.app?.appDetails) === 0,
    mobileDetails: {},
    isSendingOtp: false,
    isVerifyingOtp: false,
    isOtpSent: false,
    isOtpVerified: false,
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleRegisterConfigChange = (config) => {
    setRegisterConfig((p) => ({ ...p, ...config }));
  };

  const sendOtp = () => {
    const isOtpVerified = OTP === 0 ? false : true;
    handleRegisterConfigChange({
      isSendingOtp: true,
      isOtpVerified: isOtpVerified,
    });
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    payload.mobile = phoneNumber.nationalNumber;
    payload.country_code = phoneNumber.countryCallingCode;

    dispatch(sendOtpToMobile(payload))
      .then((res) => {
        handleRegisterConfigChange({
          mobileDetails: payload,
          isOtpSent: true,
          isSendingOtp: false,
        });
        if (res?.data?.error === false) {
          Toaster(
            APP_CONST.TOAST_TYPE.SUCCESS,
            res?.data?.message || "OTP sent"
          );
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.data?.message);
        }
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err?.response?.data?.message);
        handleRegisterConfigChange({
          isSendingOtp: false,
        });
      });
  };

  const verifyOtp = () => {
    handleRegisterConfigChange({
      isVerifying: true,
    });
    dispatch(mobileVerification({ ...registerConfig.mobileDetails, otp }))
      .then((res) => {
        handleRegisterConfigChange({
          isVerifying: false,
          isOtpVerified: true,
        });
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP verified");
      })
      .catch((err) => {
        handleRegisterConfigChange({
          isVerifying: false,
        });
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
      });
  };

  const checkUserName = async () => {
    if (formData?.username) {
      const response = await checkIfUserNameExist({
        username: formData?.username,
      });
      if (response && response?.data?.error === false) {
        setUserNameConfig({
          userNameExist: false,
          message: "username is available",
        });
      } else {
        setUserNameConfig({
          isUserNameExist: true,
          message: "username is already taken",
        });
      }
    }
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas register-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
      >
        {/* <Modal.Title>Create an account!</Modal.Title> */}
      </Modal.Header>

      <Modal.Body>
        <div className="instantID">
          <p>
            Now <span>Create</span> an Account easily on <span>Whatsapp</span>
          </p>
          <div className="whatsappid_link">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              <img src={Whatsapp} /> For Instant Id
            </a>
          </div>
        </div>

        <div className="reg_seperator">
          <span>OR</span>
        </div>

        <Modal.Title>AUTOMATIC REGISTER</Modal.Title>
        <div className="form-steps">
          <Form>
            <Form.Group
              className="mb-4 position-relative"
              controlId="formBasicPassword"
            >
              {/* <label>Password</label> */}
              <Form.Control
                type={"text"}
                name="name"
                placeholder="Name*"
                onChange={handleChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-4 position-relative"
              controlId="formBasicPassword"
            >
              {/* <label>User Name</label> */}
              <Form.Control
                type="text"
                name="username"
                placeholder="Username*"
                onChange={handleChange}
                isInvalid={!!errors.userName}
                onBlur={checkUserName}
              />

              <Form.Control.Feedback type="invalid">
                {errors?.userName}
              </Form.Control.Feedback>
            </Form.Group>
            <div
              className="user_avail"
              style={
                userNameConfig.isUserNameExist
                  ? { color: "#bc2f3e", fontSize: "12px" }
                  : { color: "#198754", fontSize: "12px" }
              }
            >
              {formData?.username && userNameConfig.message}
            </div>
            <Form.Group
              className="mb-4 position-relative"
              controlId="formBasicPassword"
            >
              {/* <label>Password</label> */}
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password*"
                autoComplete="new-password"
                onChange={(e) => {
                  handleChange(e);
                  checkForPasswordValidations(e);
                }}
                isInvalid={!!errors.password}
                onFocus={() => {
                  setShowPasswordValidations(true);
                }}
              />
              <span className="icon" onClick={() => setShowPassword((p) => !p)}>
                {showPassword ? <IoIosEyeOff /> : <IoIosEye />}
              </span>
              <Form.Control.Feedback type="invalid">
                {errors?.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-4 position-relative"
              controlId="formBasicPassword"
            >
              {/* <label>Confirm Password</label> */}
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                placeholder="confirm password*"
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword}
                onFocus={() => {
                  setShowPasswordValidations(true);
                }}
              />
              <span
                className="icon"
                onClick={() => setShowConfirmPassword((p) => !p)}
              >
                {showConfirmPassword ? <IoIosEyeOff /> : <IoIosEye />}
              </span>
              <Form.Control.Feedback type="invalid">
                {errors?.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
            {showPasswordValidtions && (
              <div className="password-note">
                <ul>
                  {validationMessages.map((item, index) => {
                    const result = validationIndex.find((i) => i === item.key);
                    return (
                      <li className={result ? "pwd-validation-message" : ""}>
                        {item?.text}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <Form.Group
              className="mb-4 position-relative"
              controlId="formBasicPassword"
            >
              <Form.Control
                type={"text"}
                name="agent_code"
                className="Agentcode"
                placeholder="Enter Agent/Referral Code"
                value={agentCode}
                onChange={(e) => setAgentCode(e.target.value)}
              />
            </Form.Group>

            <div className="get-otp">
              <Form.Group
                className=" position-relative"
                controlId="formBasicPassword"
              >
                {/* <label>Phone Number</label> */}
                <PhoneInput
                  country={"in"}
                  countryCodeEditable={false}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  inputStyle={{ width: "100%" }}
                  onChange={(phone) => {
                    setMobileNumber(phone);
                  }}
                />
              </Form.Group>

              <Button
                variant="primary"
                onClick={sendOtp}
                disabled={
                  registerConfig.isSendingOtp ||
                  userNameConfig.isUserNameExist === true
                }
              >
                {registerConfig.isSendingOtp
                  ? "Loading.."
                  : registerConfig.isOtpSent === true
                  ? "Resend OTP"
                  : "Get OTP"}
              </Button>
              <p className="otpnote">
                You will receive otp from AX-VERBO.
                <br />
                You can request a new otp after every 05 minutes
              </p>
            </div>
            {OTP === 0 ? (
              <div className="verify-otp">
                {/* <label>OTP</label> */}
                <Form.Group
                  className="mb-4 position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP*"
                    onChange={(e) => setOtpValue(e.target.value)}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  onClick={verifyOtp}
                  disabled={
                    registerConfig.isVerifyingOtp ||
                    !registerConfig.isOtpSent ||
                    !otp
                  }
                >
                  {!registerConfig.isOtpVerified
                    ? !registerConfig.isVerifyingOtp
                      ? "VERIFY OTP"
                      : "SUBMITTING"
                    : "VERIFIED"}
                </Button>
              </div>
            ) : null}

            <Button
              variant="primary"
              type="submit"
              className="btn_primary submit-btn"
              onClick={handleSubmit}
              disabled={
                !registerConfig.isOtpVerified ||
                userNameConfig.isUserNameExist === true
              }
            >
              Register
            </Button>
            <Button
              variant="primary"
              className="otp-less-login ms-4"
              onClick={() => {
                if (agentCode) {
                  window.location.href = `/login/otpless?agentCode=${agentCode}`;
                } else {
                  window.location.href = `/login/otpless`;
                }
              }}
            >
              OTP Less Login
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RegisterModal;

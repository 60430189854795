import React from "react";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

const Page404 = ({ isAuth }) => {
  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <div className="page-404">
        <div className="content">
          <h2>Oops!</h2>
          <h4>404 - Page Not Found</h4>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <a href="/home" className="btn btn-primery">
            {isAuth ? "Back to Home" : "Go to Sign In"}
          </a>
        </div>
      </div>
    </>
  );
};

export default Page404;

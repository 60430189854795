import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Gmail from "../assets/images/gmail-icon.svg";
import Whatsapp from "../assets/images/icons/whatsapp.png";
import Telegram from "../assets/images/icons/telegram.png";
import Facebook from "../assets/images/icons/facebook.png";
import Instagram from "../assets/images/icons/instagram.png";
import RNG from "../assets/images/providers/RNGCertified.png";
import Gambling from "../assets/images/providers/GamblingCommission.png";
import ResponsibleG from "../assets/images/providers/ResponsibleGamingFounding.png";
import Gamble18 from "../assets/images/providers/18+gambleresponsibly.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import UPIImg from "../assets/images/providers/upi.svg";
import VisaMasterCardImg from "../assets/images/providers/visa-master.svg";
import VisaMasterCardImg2 from "../assets/images/providers/visa-master2.svg";
import PaytmImg from "../assets/images/providers/paytm.svg";
import NetBankingImg from "../assets/images/providers/net-banking.svg";

import BitcoinImg from "../assets/images/providers/crypto/Bitcoin.png";
import EthereumImg from "../assets/images/providers/crypto/Ethereum.png";
import TetherImg from "../assets/images/providers/crypto/Tether.png";
import DaiCoinImg from "../assets/images/providers/crypto/DaiCoin.png";
import DogecoinImg from "../assets/images/providers/crypto/Dogecoin.png";
import LitecoinImg from "../assets/images/providers/crypto/Litecoin.png";
import { FaSpinner } from "react-icons/fa";

import HomeIconHover from "../assets/images/games-icon/new/home-icon-silhouette.png";
import ExchangeHover from "../assets/images/games-icon/new/cricket.png";
import SportsIconHover from "../assets/images/games-icon/new/football.png";
import EzugiIconHover from "../assets/images/games-icon/new/lottery.png";
import CashierIconHover from "../assets/images/games-icon/new/money.png";

import BrandHeading from "../assets/images/icons/bird.png";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState("");
  const location = useLocation();

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
    "exchange",
    "betby",
    "atlas-lobby",
    "/sports/Inplay",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} md={6} lg={4} className="text-center">
            <div className="foot-logo">
              <a href="/">
                {appDetails?.LOGO_URL ? (
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                ) : (
                  <FaSpinner />
                )}
              </a>
              <div className="brandheading">
                <img src={BrandHeading} alt="BrandHeading" />
                <div className="BrandName">
                  <h2>Our Partner</h2>
                  <h1>Equirise</h1>
                </div>
              </div>
            </div>
            <div className="about-site">
              <p>{appDetails?.FOOTER_TEXT || ""}</p>
            </div>
            <div className="social-icons">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "javascript:void(0)"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.TELEGRAM != ""
                        ? appDetails?.TELEGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.FACEBOOK != ""
                        ? appDetails?.FACEBOOK
                        : "javascript:void(0)"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src={Facebook} alt="Facebook Icon" />
                  </a>
                </li>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "javascript:void(0)"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>

          <Col xs={12} md={6} lg={4} className="quick-linksSection">
            <div className="quick-links">
              <h1>Quick Links</h1>
              <ul>
                <li>
                  <a href="/contactus">Contact Us</a>
                </li>
                <li>
                  <a href="/privacypolicy ">Privacy Policy</a>
                </li>
                <li>
                  <a href="/responsiblegame">Responsible Gaming</a>
                </li>
                <li>
                  <a href="/fairplay">Fair Play</a>
                </li>
                <li>
                  <a href="/gamesrule">Games Rules</a>
                </li>
                <li>
                  <a href="/terms">Terms and Conditions</a>
                </li>
              </ul>
            </div>
          </Col>

          <Col xs={12} lg={4} className="d-flex d-md-block flex-wrap">
            <div className="payment-methods">
              <h1>Payment Methods</h1>
              <ul>
                <li>
                  <img src={UPIImg} alt="upi" />
                </li>
                <li className="visa-mstr">
                  <img
                    src={VisaMasterCardImg}
                    alt="visa/master casrd"
                    className="d-none d-md-block"
                  />
                  <img
                    src={VisaMasterCardImg2}
                    alt="visa/master casrd"
                    className="d-md-none"
                  />
                </li>
                <li>
                  <img src={PaytmImg} alt="paytm" />
                </li>
                <li>
                  <img src={NetBankingImg} alt="net banking" />
                </li>
              </ul>
              <ul className="crypto-icons">
                <li>
                  <img src={BitcoinImg} alt="bitcoin" />
                </li>
                <li>
                  <img src={EthereumImg} alt="ethereum" />
                </li>
                <li>
                  <img src={TetherImg} alt="tether" />
                </li>
                <li>
                  <img src={DaiCoinImg} alt="daiCoin" />
                </li>
                <li>
                  <img src={DogecoinImg} alt="dogecoin" />
                </li>
                <li>
                  <img src={LitecoinImg} alt="litecoin" />
                </li>
              </ul>
              {/* <p style={{ color: "#848484" }}>
                  Accept, process &amp; disburse digital payments for your
                  business.
                </p> */}
            </div>
            <div className="responsible-gaming">
              <h1>Responsible Gaming</h1>
              <ul>
                <li>
                  <img
                    src={RNG}
                    alt="RNG Certified"
                    style={{ maxWidth: "90px" }}
                    height={44}
                  />
                </li>
                <li>
                  <img
                    src={Gambling}
                    alt="Gambling Commission"
                    style={{ maxWidth: "80px" }}
                    height={22}
                  />
                </li>
                <li>
                  <img
                    src={ResponsibleG}
                    alt="Responsible Gaming"
                    style={{ maxWidth: "120px" }}
                    height={35}
                  />
                </li>
                <li>
                  <img
                    src={Gamble18}
                    loading="lazy"
                    alt="Gamble Responsible"
                    style={{ maxWidth: "120px" }}
                    height={32}
                  />
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12" className="text-center">
            <p className="mt-4 mb-0">
              &copy; 2024 betrich.io. All rights reserved.
            </p>
          </Col>
        </Row>

        <div className="helpline d-none">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                : "javascript:void(0)"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            <img src={Whatsapp} /> Whatsapp <p>{appDetails?.WHATS_APP_URL}</p>
          </a>
          <div className="facebook-footer">
            <a
              className="panel"
              href={
                appDetails?.TELEGRAM != ""
                  ? appDetails?.TELEGRAM
                  : "javascript:void(0)"
              }
              target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
            >
              <img src={Telegram} alt="telegram" />
            </a>
            <a
              className="panel social-icon watsapp"
              href={
                appDetails?.FACEBOOK != ""
                  ? appDetails?.FACEBOOK
                  : "javascript:void(0)"
              }
              target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
            >
              <img src={Facebook} />
            </a>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-2 d-none">
          <p style={{ color: "rgb(204, 204, 204)" }}>
            Accept, process &amp; disburse digital payments for your business.
          </p>
        </div>

        <div className="d-flex justify-content-center mt-3 mb-3 d-none">
          <img
            src={Betfair}
            loading="lazy"
            className="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                className={activePage === "/sports/Inplay" ? "active" : ""}
                href="/sports/Inplay"
              >
                {/* <SportsIcon /> */}
                <img src={ExchangeHover} className="hover" />

                <span>Exchange</span>
              </a>
            </li>
            {/* <li>
              <a href="/sports">
                <img src={Exchange} alt="Exchange Icon" />
                <span>Exchange</span>
              </a>
            </li> */}
            <li>
              <a
                className={activePage === "/atlas-lobby" ? "active" : ""}
                href="/atlas-lobby"
              >
                {/* <SportsIcon /> */}
                <img src={SportsIconHover} className="hover" />

                <span>Sportsbook</span>
              </a>
            </li>
            <li>
              <a className={activePage === "home" ? "active" : ""} href="/home">
                {/* <HomeIcon /> */}
                <img src={HomeIconHover} className="hover" />

                <span>Home</span>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi">
                {/* <GamesIcon /> */}
                <img src={EzugiIconHover} className="hover" />

                <span>Casino</span>
              </a>
            </li>
            <li>
              <a
                className={activePage === "deposit" ? "active" : ""}
                href="/gatewaylist"
              >
                {/* <CashierIcon /> */}
                <img src={CashierIconHover} className="hover" />

                <span>Deposit</span>
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import BackIcon from "../../../../assets/svg/BackIcon";

// import { CasinoGames } from "../../../../lib/CasinoGames";
import { SlotGames } from "../../../../lib/data/SlotGames.data";
import CasinoCard from "../../../components/CasinoCard";
import { useSelector } from "react-redux";

import SearchIcon from "../../../../assets/images/icons/magnifying-glass-search.png";
import { useNavigate } from "react-router-dom";

const Slots = () => {
  const categoriesTab = [
    { name: "Netent", key: "netent" },
    { name: "RedTiger", key: "redTiger" },
    // { name: "Qtech", key: "qtech", redirectUrl: "/casino/qtech" },
    // { name: "Vivo", key: "vivo", redirectUrl: "/casino/vivo" },
  ];
  const [activeCategory, setActiveCategory] = useState("netent");
  const { isAuth } = useSelector((state) => state.auth);
  const [filteredData, setFilteredData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (event) => {
    const value = event.target.value;

    if (value) {
      setIsSearched(true);
      let filtered = [];
      Object.values(SlotGames)
        .filter((f) => f?.key === activeCategory)
        .map((slide) => {
          Object.values(slide)
            .filter((f) => typeof f !== "string")
            .map((game) => {
              let v = Object.values(game).filter(
                (f) => f?.name?.toLowerCase().indexOf(value.toLowerCase()) > -1
              );
              let clonedArray = [...filtered];
              clonedArray = [...filtered, ...v];
              filtered = clonedArray;
            });
        });
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
      setIsSearched(false);
    }
  };

  return (
    <>
      <HeaderAfterLogin />

      <main className="main">
        <div className="slots-sectionPage MobileGamesSection">
          <div className="games-section">
            <div className="headingSec">
              <h1>Slots</h1>
              <div className="back-link" onClick={() => navigate(-1)}>
                <BackIcon /> Back
              </div>
            </div>

            <div className="SlotsTabsSection">
              <ul>
                {categoriesTab.map((tab, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setFilteredData([]);
                      setIsSearched(false);
                      if (tab?.redirectUrl) {
                        navigate(tab?.redirectUrl);
                      } else {
                        setActiveCategory(tab.key);
                      }
                    }}
                    style={
                      activeCategory === tab.key
                        ? {
                            background: "#222430",
                          }
                        : {}
                    }
                  >
                    <div
                      href="#"
                      style={
                        activeCategory === tab.key
                          ? {
                              color: "#ffb80c",
                            }
                          : {}
                      }
                    >
                      {tab.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="SearchBar">
              <img src={SearchIcon} alt="search" className="search" />
              <input
                type="text"
                placeholder="Search Games"
                onChange={handleSearch}
              />
            </div>

            {!isSearched ? (
              <ul>
                {Array.isArray(Object.values(SlotGames)) &&
                  Object.values(SlotGames)
                    .filter((f) => f?.key === activeCategory)
                    .map((slide) =>
                      Object.values(slide)
                        .filter((f) => typeof f !== "string")
                        .map((game) =>
                          Object.values(game).map((item, idx) => (
                            <CasinoCard key={"casinogame" + idx} data={item} />
                          ))
                        )
                    )}
              </ul>
            ) : (
              <ul>
                {(filteredData.length &&
                  filteredData.map((game, idx) => (
                    <CasinoCard key={"casinogame" + idx} data={game} />
                  ))) ||
                  null}
              </ul>
            )}
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Slots;

import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  logoutUser,
  setPassword,
  updateProfile,
} from "../../redux/auth/actions";
import { Toaster } from "../../utils/toaster";
import { APP_CONST, UPDATE_PASSWORD_FLAG_KEY } from "../../config/const";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { profileData } from "../../redux/user/actions";

const UpdatePasswordModal = ({ show, setShow }) => {
  // const { user } = useSelector((state) => state.auth);
  const [user, setuser] = useState();

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [passwordFields, setPasswordFields] = useState({});
  const [profileFields, setProfileFields] = useState({});
  const [passwordVisibilty, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  });
  const [loaders, setLoaders] = useState({ profile: false, password: false });
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await profileData();
        setuser(response?.data);
      } catch (err) {
        console.log(err.message);
      }
    };
    getData();
  }, []);
  // Validate password fields
  const validateData = (data) => {
    const dataToValidate = data ? data : passwordFields;
    const { confirmPassword, newPassword } = dataToValidate;

    let errorObj = {};
    if (!Boolean(confirmPassword)) {
      errorObj.confirmPassword = "Confirm password is required";
    }
    if (!Boolean(newPassword)) {
      errorObj.newPassword = "Password is required";
    } else if (confirmPassword !== newPassword) {
      errorObj.newPassword = "Confirm password and password must match";
    }
    setErrors(errorObj);
    return errorObj;
  };

  // Handle password field change
  const handleSetPassword = async (e) => {
    e.preventDefault();
    const vaildationErrors = validateData(passwordFields);
    if (Object.keys(vaildationErrors).length === 0) {
      setLoaders((p) => ({ ...p, password: true }));
      const passwordPromise = await setPassword({
        newPassword: passwordFields?.newPassword,
      });
      setLoaders((p) => ({ ...p, password: false }));
      if (passwordPromise) {
        dispatch(logoutUser());
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Password set success");
      }
      handleClose();
    }
  };

  // Handle set password
  const handlePasswordFieldChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const data = { ...passwordFields, [name]: value };
    validateData(data);
    setPasswordFields(data);
  };

  // Handle profile update
  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoaders((p) => ({ ...p, profile: true }));
    const profilePromise = await updateProfile({
      fullName: profileFields?.fullName,
    });
    setLoaders((p) => ({ ...p, profile: false }));
    if (profilePromise) {
      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Profile updated");
    }
  };

  const handleClose = () => {
    localStorage.removeItem(UPDATE_PASSWORD_FLAG_KEY);
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        className="changePasswordModal UpdateProfileModal"
        // onHide={}
      >
        <Modal.Header>
          <div className="d-flex flex-column">
            <Modal.Title>Update Your Account Details</Modal.Title>
            <p>
              <b>Your Username :</b>
              {user?.userName}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleProfileUpdate}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter full name"
                name="fullName"
                onChange={(e) => {
                  setProfileFields({ fullName: e.target.value });
                }}
              />
            </Form.Group>
            <Button type="submit" disabled={loaders.profile}>
              {loaders.profile ? "Loading..." : "Update Profile"}
            </Button>
          </Form>
          <Form style={{ paddingTop: "10px" }} onSubmit={handleSetPassword}>
            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicEmail"
            >
              <Form.Control
                type={passwordVisibilty.password === true ? "text" : "password"}
                placeholder="Enter new Password"
                name="newPassword"
                onChange={handlePasswordFieldChange}
                isInvalid={!!errors.password}
              />
              <span
                className="icon"
                onClick={() =>
                  setPasswordVisibility((p) => ({
                    ...p,
                    password: !p.password,
                  }))
                }
              >
                {passwordVisibilty.password ? (
                  <IoEyeOffOutline />
                ) : (
                  <IoEyeOutline />
                )}
              </span>
              <Form.Control.Feedback type="invalid">
                {errors?.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicEmail"
            >
              <Form.Control
                type={
                  passwordVisibilty.confirmPassword === true
                    ? "text"
                    : "password"
                }
                placeholder="Enter confirm password"
                name="confirmPassword"
                onChange={handlePasswordFieldChange}
                isInvalid={!!errors.confirmPassword}
              />
              <span
                className="icon"
                onClick={() =>
                  setPasswordVisibility((p) => ({
                    ...p,
                    confirmPassword: !p.confirmPassword,
                  }))
                }
              >
                {passwordVisibilty.confirmPassword ? (
                  <IoEyeOffOutline />
                ) : (
                  <IoEyeOutline />
                )}
              </span>
              <Form.Control.Feedback type="invalid">
                {errors?.newPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <p>
              <b>Note:</b> Save above username & set password for future user to
              login to your account.
              <p>
                "The Password reset is valid for only 10 minutes. please ensure
                you complete the process within this time to successfully reset
                your password."
              </p>
            </p>

            <Button variant="primary" type="submit" disabled={loaders.password}>
              {loaders.password ? "Loading..." : "Set Password"}
            </Button>
          </Form>
        </Modal.Body>

        {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer> */}
      </Modal>
    </>
  );
};
export default UpdatePasswordModal;

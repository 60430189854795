import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  checkIfDemoUserExist,
  demoLogin,
  forgotPassword,
  loginUser,
  resetPassword,
} from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { APP_CONST } from "../../../config/const";
import PhoneInput from "react-phone-input-2";
import { Toaster } from "../../../utils/toaster";
import { parsePhoneNumber } from "libphonenumber-js";
import ForwardArrow from "../../../assets/images/icons/next.png";
import ForwardArrow1 from "../../../assets/images/icons/right-arrow.png";
import LoaderCircle from "../../../assets/images/LoaderCircle.gif";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [mobileNumber, setMobileNumber] = useState("");
  const [changeModalContent, setChangeModalContent] = useState(true);
  const [otp, setOtpValue] = useState("");
  const [password, setPassword] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isDemoUserExist, setIsDemoUserExist] = useState(false);
  const [loaders, setLoaders] = useState({
    login: false,
    demoLogin: false,
  });

  useEffect(() => {
    checkDemoUser();
  }, []);

  /**
   *
   * @param {string} keyName
   * @param {boolean} booleanValue
   */
  const updateLoaders = (keyName, booleanValue) => {
    setLoaders((p) => ({ ...p, [keyName]: booleanValue }));
  };

  const checkDemoUser = async () => {
    const response = await checkIfDemoUserExist();
    if (response && response?.data?.error === false) {
      setIsDemoUserExist(response?.data?.data?.hasDemoUsers === true);
    }
  };

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();

    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      updateLoaders("login", true);
      let loginData = { ...formData };
      // handleClose();
      dispatch(
        loginUser(loginData, () => {
          updateLoaders("login", false);
        })
      );
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const handleSubmitForgotPass = async (e) => {
    e.preventDefault();
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            setIsOtpSent(true);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };

  const handleSubmitResetPass = () => {
    setIsVerifying(true);

    if (otp == "" || password == "") {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "All fields are mandatory");
    } else {
      const payload = {
        otp,
        password,
      };

      resetPassword(payload)
        .then((res) => {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Password reset success");
          setChangeModalContent((prev) => !prev);
        })
        .catch((err) => {
          Toaster(
            APP_CONST.TOAST_TYPE.ERROR,
            err.response.data.message || "Request failed"
          );
          setIsVerifying(false);
        });
    }
  };

  const loginDemoUser = (e) => {
    e.preventDefault();
    updateLoaders("demoLogin", true);
    dispatch(
      demoLogin(() => {
        updateLoaders("demoLogin", false);
      })
    );
  };

  return (
    <Modal
      show={show}
      id="loginModal"
      className={"steps-canvas login-modal LoginModal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      ></Modal.Header>
      <Modal.Body>
        {changeModalContent ? (
          <div
            className={
              !changeModalContent ? `login-section` : `login-section active`
            }
          >
            <div className="Login-rightPart LoginNewDesign">
              <Modal.Title>Log in to Your Account</Modal.Title>
              <div className="form-steps">
                <Form>
                  <Form.Group className="form-floating mb-4">
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="Enter Username"
                      onChange={handleChange}
                      isInvalid={!!errors.username}
                    />
                    <label htmlFor="formBasicName">Enter Username</label>
                    <Form.Control.Feedback type="invalid">
                      {errors?.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-floating mb-4">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password*"
                      name="password"
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <label htmlFor="formBasicName1">Password</label>
                    <span
                      className="icon"
                      onClick={() => setShowPassword((s) => !s)}
                    >
                      {showPassword ? <IoIosEyeOff /> : <IoIosEye />}
                    </span>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {errors?.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className="d-flex justify-content-start mb-3">
                    <a
                      className="form-link"
                      onClick={() => {
                        setChangeModalContent((prev) => !prev);
                      }}
                    >
                      Forgot password?
                    </a>
                  </div>
                  <div className="d-flex justify-content-start mb-3">
                    <a
                      className="form-link passwordlesslogin"
                      onClick={() => {
                        window.location.href = "/login/otpless";
                      }}
                    >
                      Password Less Log-in{" "}
                      <img src={ForwardArrow1} alt="ForwardArrow" />
                    </a>
                  </div>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loaders?.login}
                  >
                    {loaders?.login ? "Loading..." : "Log in"}
                  </Button>
                  <p>
                    Don't have an account ? <a href="/login/otpless">Sign up</a>
                  </p>
                </Form>
                {!isDemoUserExist ? (
                  <img src={LoaderCircle} style={{ width: "40px" }} />
                ) : (
                  isDemoUserExist && (
                    <>
                      <div className="mb-1 Demologin">
                        Need Demo? Just Click the button below.
                      </div>
                      <div
                        className="demo-login mt-1 btn btn-primary"
                        onClick={loginDemoUser}
                        disabled={loaders?.demoLogin}
                      >
                        {loaders?.demoLogin ? (
                          <img src={LoaderCircle} style={{ width: "30px" }} />
                        ) : (
                          "Demo Login"
                        )}
                      </div>
                    </>
                  )
                )}

                <div className="sportsSection">
                  {/* <img src={MumbaiSports} /> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              changeModalContent
                ? `login-section`
                : `login-section ForgotPasswordSection`
            }
            // {`login-section  ForgotPasswordSection `}
          >
            <div className="Login-rightPart ForgotPassword">
              <Modal.Title>Reset Password</Modal.Title>
              <div className="form-steps">
                <Form onSubmit={handleSubmitForgotPass} className="d-none">
                  <Form.Group
                    className="position-relative"
                    controlId="formBasicPassword"
                  >
                    {/* <label>Phone Num</label> */}
                    <PhoneInput
                      country={"in"}
                      countryCodeEditable={false}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      // onlyCountries={["in"]}
                      inputStyle={{
                        width: "100%",
                      }}
                      onChange={(phone) => {
                        // alert("phone", phone);
                        // alert(phone);
                        setMobileNumber(phone);
                      }}
                    />
                  </Form.Group>
                  <p className="otpnote">You will receive otp from AX-VERBO.</p>
                  <Button
                    variant="primary mb-4"
                    type="submit"
                    // onClick={handleSubmitForgotPass}
                  >
                    Get OTP
                  </Button>

                  <Form.Group className="mb-4 position-relative" controlId="">
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP*"
                      onChange={(e) => setOtpValue(e.target.value)}
                      autoComplete="off"
                    />
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="new-password"
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    onClick={handleSubmitResetPass}
                    disabled={!isOtpSent}
                  >
                    {!isVerifying ? "SUBMIT" : "SUBMITTING"}
                  </Button>
                </Form>

                <h1>
                  Mobile Verification <b>Successful</b>
                </h1>
                <p className="optionp">
                  Please select most suitable option below
                </p>

                <a href="/contactuss" className="contactbtn mt-1">
                  Contact Us to Reset your Password{" "}
                  <img src={ForwardArrow} alt="ForwardArrow" />
                </a>

                <h2>OR</h2>

                <a
                  href="/login/otpless"
                  className="contactbtn passwordbtn mt-1"
                >
                  Password-Less Log in
                  <img src={ForwardArrow} alt="ForwardArrow" />
                </a>

                {/* <div
                  className="my-1 LoginBack"
                  onClick={() => setChangeModalContent((prev) => !prev)}
                >
                  Back To Login
                </div> */}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;

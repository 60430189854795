import React, { useState } from "react";
import HeartIcon from "../../assets/svg/HeartIcon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function CasinoCard({ data, imageUrlCheck = false, imgHeight }) {
  const navigate = useNavigate();
  const [showAuthModals, setShowAuthModals] = useState(false);
  const { isAuth } = useSelector((state) => state.auth);

  const getredirectionURL = (data) => {
    if (isAuth) {
      const redirectUrl = data?.href || data?.redirectUrl || data?.cover || "#";

      window.location.href = redirectUrl;
    } else {
      setShowAuthModals(true);
      // navigate("#");
    }
  };
  const getImageUrl = (url) => {
    if (imageUrlCheck && url) {
      return url?.slice(1, url.length) || url;
    }
    return url || "";
  };

  return (
    <a onClick={() => getredirectionURL(data)} className="casino_games">
      <div className="shadow position-relative">
        <img
          src={getImageUrl(data?.cover?.src || data?.imgUrl)}
          alt={data?.cover?.alt || ""}
          height={imgHeight}
        />

        <div className="overlayslideright">
          <div className="textslideright">Play Now</div>
        </div>
      </div>
      <p>{data?.GameName || data?.name}</p>
    </a>
  );
}

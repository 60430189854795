import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/images/telegram-icon.svg";
import WhatsappIcon from "../assets/images/whatsapp-icon.svg";
import FBIcon from "../assets/images/facebook-icon.svg";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/icons/btmhead_inplay.png";
import BotHeadIcon2 from "../assets/images/icons/btmhead_cricket.png";
import BotHeadIcon5 from "../assets/images/games-icon/head.png";
import BotHeadIcon6 from "../assets/images/games-icon/playing-cards.png";
import BotHeadIcon7 from "../assets/images/games-icon/roulette.png";

import SportsIcon1 from "../assets/images/games-icon/new/sportsbook.webp";
import HomeIcon1 from "../assets/images/games-icon/new/home.webp";
import EzugiIcon from "../assets/images/games-icon/new/roulette.webp";
import EvolutionIcon from "../assets/images/games-icon/new/evoluton.webp";
import CashierIcon1 from "../assets/images/games-icon/new/cashier.webp";
import Exchange from "../assets/images/games-icon/new/exchange.webp";
import Sportsbook2 from "../assets/images/games-icon/new/football.webp";

import HomeIconHover from "../assets/images/games-icon/new/home-icon-silhouette.png";
import ExchangeHover from "../assets/images/games-icon/new/cricket.png";
import SportsIconHover from "../assets/images/games-icon/new/football.png";
import EzugiIconHover from "../assets/images/games-icon/new/lottery.png";
import EvolutionIconHover from "../assets/images/games-icon/new/dice.png";
import CashierIconHover from "../assets/images/games-icon/new/money.png";
import Sportsbook2Hover from "../assets/images/games-icon/new/beach-ball.png";

import AllGames from "../assets/images/games-icon/allgames-icon.png";
import CricketIcon from "../assets/images/games-icon/cricket-icon.png";
import SoccerIcon from "../assets/images/games-icon/soccer-icon.png";
import TennisIcon from "../assets/images/games-icon/tennis-icon.png";
import GreyhoundRacingIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import HorseRacingIcon from "../assets/images/games-icon/horseracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";
import VirtualCricketIcon from "../assets/images/games-icon/virtualcricket-icon.png";
import SnookerIcon from "../assets/images/games-icon/snooker-icon.png";
import SpaceIcon from "../assets/images/games-icon/space-icon.png";

import ArcadeGames from "../assets/images/games-icon/arcade-games.png";
import Aviator1 from "../assets/images/games-icon/aviator.svg";
import Aviator from "../assets/images/icons/icon.png";
import Dice from "../assets/images/games-icon/dice.svg";
import Plinko from "../assets/images/games-icon/plinko.svg";
import Goal from "../assets/images/games-icon/goal.svg";
import Hilo from "../assets/images/games-icon/hilo.svg";
import Mines from "../assets/images/games-icon/mines.svg";
import Keno from "../assets/images/games-icon/keno.svg";
import Miniroulette from "../assets/images/games-icon/miniroulette.svg";
import Hotline from "../assets/images/games-icon/hotline.svg";

import { useSelector } from "react-redux";
import ModalWrapper from "./ModalWrapper";
import { APP_CONST } from "../config/const";
import { AiOutlineClose } from "react-icons/ai";
import { getNotifications } from "../redux/app/actions";
import { FaSpinner } from "react-icons/fa";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const handleModalShow = (modalName) => {
    setShowAuthModals(true);

    setDefaultModal(modalName);
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    console.log(showAuthModals, "showAuthModals");
  }, [showAuthModals]);

  // const [isSubMenuActive, setIsSubMenuActive] = useState(false);
  const [isArcadeGamesActive, setIsArcadeGamesActive] = useState(false);
  const [isAllSportsActive, setIsAllSportsActive] = useState(false);
  // const inlineStyle = isSubMenuActive ? { overflowX: "clip" } : {};
  const inlineStyle =
    isArcadeGamesActive || isAllSportsActive ? { overflowX: "clip" } : {};

  // const handleMenuClick = () => {
  //   setIsSubMenuActive(!isSubMenuActive);
  // };
  const handleArcadeGamesClick = () => {
    setIsArcadeGamesActive(!isArcadeGamesActive);
    setIsAllSportsActive(false); // Close AllSports dropdown if it's open
  };
  const handleAllSportsClick = () => {
    setIsAllSportsActive(!isAllSportsActive);
    setIsArcadeGamesActive(false); // Close Arcade Games dropdown if it's open
  };

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee
            onMouseOver={(e) => e.target.stop()}
            onMouseOut={(e) => e.target.start()}
          >
            {websiteNotifications.map((notf, idx) => notf?.content + " ")}
          </marquee>
          <h2 onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </h2>
        </div>
      )) ||
        null}

      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="logo">
                    <a href="/">
                      {appDetails?.LOGO_URL ? (
                        <img
                          src={appDetails?.LOGO_URL}
                          alt="Logo"
                          height={26}
                        />
                      ) : (
                        <FaSpinner />
                      )}
                    </a>
                  </div>

                  <div className="middle-part">
                    <Navbar expand="lg">
                      <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="">
                            <Nav.Link href="/">
                              {/* <HomeIcon />  */}
                              <div className="Headicon">
                                <img src={HomeIcon1} className="nonhover" />
                                <img src={HomeIconHover} className="hover" />
                              </div>
                              <h2>Home</h2>
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                              className=""
                            >
                              {/* <SportsIcon /> */}
                              <div className="Headicon">
                                {/* <SportsIcon /> */}
                                <img src={SportsIcon1} className="nonhover" />
                                <img src={SportsIconHover} className="hover" />
                              </div>
                              <h2>Exchange</h2>
                            </Nav.Link>
                            <Nav.Link href="/atlas-lobby">
                              <div className="Headicon">
                                <img
                                  src={Exchange}
                                  alt="Exchange Icon"
                                  className="nonhover"
                                />{" "}
                                <img src={ExchangeHover} className="hover" />
                              </div>
                              <h2>Sportsbook</h2>
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                            >
                              <div className="Headicon">
                                <img
                                  src={Sportsbook2}
                                  alt="Exchange Icon"
                                  className="nonhover"
                                />{" "}
                                <img src={Sportsbook2Hover} className="hover" />
                              </div>
                              <h2>Sportsbook 2</h2>
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                            >
                              <div className="Headicon">
                                <img src={EzugiIcon} className="nonhover" />
                                <img src={EzugiIconHover} className="hover" />
                              </div>
                              <h2>Ezugi</h2>
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                            >
                              {/* <GamesIcon />  */}
                              <div className="Headicon">
                                <img src={EvolutionIcon} className="nonhover" />
                                <img
                                  src={EvolutionIconHover}
                                  className="hover"
                                />
                              </div>
                              <h2>Evolution</h2>
                            </Nav.Link>
                            <Nav.Link
                              onClick={() => {
                                handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                              }}
                            >
                              {/* <CashierIcon /> */}
                              <div className="Headicon">
                                <img src={CashierIcon1} className="nonhover" />
                                <img src={CashierIconHover} className="hover" />
                              </div>
                              <h2>Cashier</h2>
                            </Nav.Link>
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </div>

                  <div className="headerRight">
                    {/* <ul className="social">
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          <img src={WhatsappIcon} alt="whats app" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.TELEGRAM != ""
                              ? appDetails?.TELEGRAM
                              : "#"
                          }
                          target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                        >
                          <img src={TelegramIcon} alt="telegram" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="panel"
                          href={
                            appDetails?.FACEBOOK != ""
                              ? appDetails?.FACEBOOK
                              : "javascript:void(0)"
                          }
                          target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                        >
                          <img src={FBIcon} />
                        </a>
                      </li>
                    </ul> */}

                    <Button
                      variant="primary"
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                      className="button-primary btn_signin"
                    >
                      SIGN IN
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        // handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                        window.location.href = "/login/otpless";
                      }}
                      className="button-primary btn_signup ms-2"
                    >
                      SIGN UP
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom_head">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul style={inlineStyle}>
                  {/* <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={BotHeadIcon1} alt="bottom head link icon" />
                      <h2>Inplay</h2>
                    </a>
                  </li> */}
                  <li className="allGames">
                    <a
                      href="javascript:void(0)"
                      onClick={handleArcadeGamesClick}
                    >
                      <img src={ArcadeGames} alt="all games" />
                      <h2>Arcade Games</h2>
                    </a>
                    <ul
                      className={`sports-games spribe ${
                        isArcadeGamesActive ? "active" : ""
                      }`}
                    >
                      <li>
                        <a href="/casino/spribe/aviator">
                          <img src={Aviator1} alt="bottom head link icon" />
                          <h2>Aviator</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/dice">
                          <img src={Dice} alt="bottom head link icon" />
                          <h2>Dice</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/plinko">
                          <img src={Plinko} alt="bottom head link icon" />
                          <h2>Plinko</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/goal">
                          <img src={Goal} alt="bottom head link icon" />
                          <h2>Goal</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/hilo">
                          <img src={Hilo} alt="bottom head link icon" />
                          <h2>Hilo</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/mines">
                          <img src={Mines} alt="bottom head link icon" />
                          <h2>Mines</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/keno">
                          <img src={Keno} alt="bottom head link icon" />
                          <h2>Keno</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/miniroulette">
                          <img src={Miniroulette} alt="bottom head link icon" />
                          <h2>Mini Roulette</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/casino/spribe/hotline">
                          <img src={Hotline} alt="bottom head link icon" />
                          <h2>Hotline</h2>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="allGames">
                    <a href="javascript:void(0)" onClick={handleAllSportsClick}>
                      <img src={AllGames} alt="all games" />
                      <h2>All Sports</h2>
                    </a>
                    <ul
                      className={`sports-games ${
                        isAllSportsActive ? "active" : ""
                      }`}
                    >
                      <li>
                        <a href="/sports/Cricket">
                          <img src={VirtualCricketIcon} alt="e-sports" />
                          <h2>E-Sports</h2>
                        </a>
                      </li>

                      <li>
                        <a href="/sports/Cricket">
                          <img src={CricketIcon} alt="bottom head link icon" />
                          <h2>Cricket</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Soccer">
                          <img src={SoccerIcon} alt="soccer" />
                          <h2>Football</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Tennis">
                          <img src={TennisIcon} alt="bottom head link icon" />
                          <h2>Tennis</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Horse Racing">
                          <img src={HorseRacingIcon} alt="horse racing" />
                          <h2>Horse Racing</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Greyhound Racing">
                          <img
                            src={GreyhoundRacingIcon}
                            alt="greyhound racing"
                          />
                          <h2>Greyhound Racing</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Politics">
                          <img src={PoliticsIcon} alt="politics" />
                          <h2>Politics</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/Snooker">
                          <img src={SnookerIcon} alt="snooker" />
                          <h2>Snooker</h2>
                        </a>
                      </li>
                      <li>
                        <a href="/sports/SPACE">
                          <img src={SpaceIcon} alt="space" />
                          <h2>SPACE</h2>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={Aviator} alt="bottom head link icon" />
                      <h2>Aviator</h2>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={BotHeadIcon5} alt="bottom head link icon" />
                      <h2>DragonTiger</h2>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={BotHeadIcon6} alt="bottom head link icon" />
                      <h2>TeenPatti</h2>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={BotHeadIcon7} alt="bottom head link icon" />
                      <h2>Roulette</h2>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <h2 className="top-heading">Sign in now!</h2>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <h2 className="icon">
                  <EyeIcon />
                </h2>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
          />
        )}
      </header>
    </>
  );
};

export default Header;
